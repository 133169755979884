:root {
  --accentColor: #0A043C;
  --sliderOnColor: #FF0000;
  --sliderOffColor: #CCCCCC;
  --sliderKnobColor: #FFFFFF;
  --disabledColor: #BBB;
  --buttonBackgroundColor: rgb(239,239,239);
}

body {
  margin: 0;
  font-size: 16px;
}

main {
  padding: 5px 15px;
  margin-bottom: 100px;
}

button {
  padding: 6px 12px;
  border-radius: 5px;
  border: 0;
  font-size: 1.25em;
  background-color:var(--buttonBackgroundColor);
}

input, select {
  font-size: 1em;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 1em;
  background: var(--sliderOffColor);
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
  background: var(--sliderKnobColor);
  border: 2px solid var(--sliderOffColor);
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

/* #region MainHeader */
.main-header {
  background-color: var(--accentColor);
  color: #FFFFFF;
  width: 100vw;
  height: 50px;
  position: sticky;
  top: 0;
  padding: 5px 0;
  box-sizing: content-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.main-header h1 {
  font-size: 1.125em;
  margin:0;
  padding: 0;
}

.main-header button {
  background-color: transparent;
  border: 0;
  color: #FFFFFF;
  font-size: 1em;
}

.main-header button svg {
  width: auto;
  height: 40px;
}

.main-header button svg path {
  fill: #FFFFFF;
}
/* #endregion MainHeader */

/* #region LoginButton */
button.login-button {
  color: #FFFFFF;
  font-size: 1em;
}

/* #endregion LoginButton */

/* #region ToggleSwitch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 4em;
  height: 2em;
  border-radius: 2em;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--sliderOffColor);
  transition: .4s;
  border-radius: 2em;
}

.toggle-switch .slider:before {
  position: absolute;
  content: "";
  height: 1.75em;
  width: 1.75em;
  left: 2px;
  bottom: 2px;
  background-color: var(--sliderKnobColor);
  transition: .4s;
  border-radius: 50%;
}

.toggle-switch input:checked + .slider {
  background-color: var(--sliderOnColor);
}

.toggle-switch input:focus .slider {
  box-shadow: 0 0 1px var(--sliderOnColor);
}

.toggle-switch input:checked + .slider:before {
  transform: translateX(2em);
}

/* #endregion */

/* #region RecycleToggle */
.recycle-toggle.recycle-off svg path {
  fill: var(--disabledColor);
}

.recycle-toggle.recycle-on svg path {
  fill: #000000;
}

/* #endregion RecycleToggle

/* #region HeroForm */
.hero-form form label {
  display: block;
}

.hero-form form input, select, textarea {
  width: 100%;
  margin-bottom: 5px;
}

.hero-form form textarea {
  resize: none;
  font-family: Arial;
  font-size: 1em;
  padding:3px;
}

.hero-form__ability-section {
  grid-column-start: span 2;
}

.hero-form .stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}

.hero-form__ability-section header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-form__ability-section__form, .villain-threat-section {
  border: 1px solid #000000;
  padding: 6px 12px;
  margin-bottom: 10px;
}

/* #endregion HeroForm */

/* #region VillainForm */
.villain-form form label {
  display: block;
}

.villain-form form input, select, textarea {
  width: 100%;
  margin-bottom: 5px;
}

.villain-form form textarea {
  resize: none;
  font-family: Arial;
  font-size: 1em;
  padding:3px;
}

.villain-form .healthForm {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap:12px;
}

.villain-form__threat-section header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.villain-form__threat-section input[type="checkbox"] {
  width: initial;
}

/* #endregion VillainForm */

/* #region CollectionForm, ChallengeForm, LocationForm, DifficultyForm */
.collection-form label, .challenge-form label, .location-form label, .difficulty-form label {
  display: block;
}

.collection-form input,
.challenge-form input,
.challenge-form select,
.challenge-form textarea,
.location-form input,
.location-form select,
.location-form textarea,
.difficulty-form input,
.difficulty-form select,
.difficulty-form textarea {
  width: 100%;
  margin-bottom: 5px;
}

.challenge-form textarea, .location-form textarea, .difficulty-form textarea {
  resize: none;
  font-family: Arial;
  font-size: 1em;
  padding:3px;
}

/* #endregion CollectionForm, ChallengeForm, LocationForm */

/* #region Collection */
.collection-page img {
  width: 100%;
  height: auto;
}
/* #endregion Collection */

/* #region NewGame */
.new-game__content {
  min-height: 65vh;
}

.new-game__content h1 {
  text-align: center;
}

.new-game__heroes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:20px;
  width:100%;
  grid-column-start: span 2;
}

.new-game__buttons {
  margin:25px 0;
  display: flex;
  justify-content: flex-end;
}

.new-game__buttons button svg {
  height: 40px;
  width:auto;
}

.new-game__buttons button:disabled svg path {
  fill: var(--disabledColor);
}

/* #endregion NewGame */

/* #region CharacterSelector */
.character-selector {
  border: 2px solid #000;
  max-width: 290px;
  padding:5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.character-selector img {
  border: 1px solid #000;
  width: 100%;
  max-width: 180px;
  height: auto;
  display: block;
  max-height: 40vh;
}

.character-selector p {
  font-size:1.25em;
  font-weight: bold;
}

.character-selector__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.character-selector button svg {
  height:40px;
  width: auto;
}

/* #endregion CharacterSelector */

/* #region CharacterSearch */
.character-search {
  margin-bottom: 20px;
  position: relative;
}

.character-search input[type="text"] {
  width: 100%;
}

.character-search__results {
  position: absolute;
  top:1.5em;
  left: 0;
  background-color:#fff;
  z-index:2;
  width: 100%;
  border: none;
}

.character-search__results.open {
  border: 1px solid #000;
}

.character-search__result input {
  height: 0;
  width: 0;
  opacity: 0;
}
/* #endregion CharacterSearch */

/* #region LogPlay */
.log-play label {
  font-size: 1em;
  display: block;
  margin:5px 0;
}

.log-play__buttons {
  display: flex;
  justify-content: flex-end;
}
/* #endregion LogPlay */

/* #region SelectHeroes */
.select-heroes__hero-section input {
  margin: 12px 0 25px 0;
}
/* #endregion SelectHeroes */

/* #region SelectVillain */
.select-villain__villain {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* #endregion SelectVillain */

/* #region SelectChallenge, SelectDifficulty */
.select-challenge__toggle,
.select-difficulty__toggle {
  display: grid;
  grid-template-columns: 1fr 4em;
  margin-bottom: 20px;
}

.select-challenge__challenge,
.select-difficulty__difficulty {
  border: 2px solid #000;
  padding: 6px;
  margin-bottom: 20px;
}

.select-challenge__challenge h3,
.select-difficulty__difficulty h3 {
  margin-top:0;
}

.select-challenge__buttons,
.select-difficulty__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.select-challenge button svg,
.select-difficulty button svg {
  height:40px;
  width: auto;
}
/* #endregion SelectChallenge, SelectDifficulty */

/* #region Profile */
.emptyList {
  padding: 6px 0 6px 20px;
  font-style: italic;
}

.profile__collections {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  margin-bottom: 10px;
}

.profile__collections img {
  width: 100%;
  height: auto;
}

/* #endregion Profile */

/* #region MyCollections */
.my-collections-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
/* #endregion MyCollections */

/* #region CollectionCheckbox */
.collection-checkbox {
  box-sizing: content-box;
  padding: 6px;
  position: relative;
  text-align: center;
}

.collection-checkbox.checked img {
  box-shadow: 0 0 10px 5px blue;
  box-sizing: border-box;
}

.collection-checkbox p {
  margin: 5px 0 0 0;
}

.collection-checkbox input {
  display: none;
}

.collection-checkbox img {
  width: 100%;
  height: auto;
}
/* #endregion CollectionCheckbox */

/* #region NotificationPill */
.notification-pillbox {
  position: fixed;
  top: -10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: top 1.25s;
}

.notification-pill {
  padding: 6px 12px;
  border-radius: 5px;
  text-align: center;
  min-width: 25vw;
}

.notification-pill p {
  margin: 0;
}

.notification-pill .title {
  font-size: 1.125em;
  font-weight: bold;
  margin-bottom: 3px;
}

.notification-SUCCESS .notification-pill {
  background-color: green;
  color: #FFFFFF;
}

.notification-FAILURE .notification-pill {
  background-color: red;
  color: #FFFFFF;
}

.notification-WARNING .notification-pill {
  background-color: yellow;
  color: #000000;
}

.notification-NONE .notification-pill {
  background-color: transparent;
}

.notification-pillbox.notification-show {
  top: 10vh;
}

.notification-pillbox.notification-hide {
  top: -10vh;
}


/* #endregion NotificationPill */

/* #region fourOhFour */
.fourOhFour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fourOhFour img {
  max-width: 95vw;
}

@media screen and (min-width: 768px){
  .fourOhFour img {
    max-width: 50vw;
  }
}

.fourOhFour p {
  font-size: 2em;
}

/* #endregion fourOhFour */

/* #region MainFooter */
.main-footer {
  width: 100vw;
  height: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: var(--accentColor);
  box-sizing: border-box;

}

.main-footer a {
  background-color: transparent;
  border: 0;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  text-decoration: none;
}

.main-footer a svg {
  height: 50px;
  width: auto;
  margin-bottom: 5px;
}

.main-footer a svg path {
  fill: #FFFFFF;
}


/* #endregion MainFooter */
